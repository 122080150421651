<template>
  <div v-if="debugmode && permissions.isAdmin">
    <fw-button type="transparent" label="Debug" class="mr-2" @click.native="openModal">
      <fw-icon-code-slash class="w-5 h-5 opacity-80"></fw-icon-code-slash>
    </fw-button>
    <b-modal
      :active="active"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="800"
      :on-cancel="
        () => {
          active = false
        }
      "
      :custom-class="'rounded-buefy-modal'"
    >
      <div class="bg-white p-5 rounded-xl text-sm break-all min-w-fit">
        <div class="mb-4">
          <fw-label marginless uppercase>Debug</fw-label>
        </div>
        <div v-if="loading"><fw-icon-loading class="w-5 h-5" /></div>

        <div v-else>
          <fw-panel-info v-if="data.worker_vacations" class="mb-4" clean>
            <fw-heading class="mb-2">Direitos e contingentes a cumprir:</fw-heading>
            <p>Dias disponíveis: {{ data.worker_vacations.days }};</p>
            <p>Dias a que tem direito (ano): {{ data.worker_vacations.days_year }};</p>
            <p>Dias cumulados do ano anterior: {{ data.worker_vacations.days_previous_year }};</p>
            <p>Dias usados do ano anterior: {{ data.worker_vacations.days_used_previous_year }};</p>
            <p>Dias crédito: {{ data.worker_vacations.days_credit }};</p>
            <p>Dia de aniversário: {{ data.worker_vacations.days_birthday }};</p>
            <div v-if="data.worker_vacations.contingentes?.length">
              <p>Contingentes:</p>
              <ul class="list-inside">
                <li v-for="(c, i) in data.worker_vacations.contingentes" :key="i" class="pl-4">
                  {{
                    `${c.days} dias no contingente ${c.number} de ${c.year}, dedutível de ${c.deductible_from} a ${c.deductible_until}`
                  }};
                </li>
              </ul>
            </div>
          </fw-panel-info>

          <fw-panel-info v-if="data.sap_info?.LT_LISTA_AUSENCIAS || data.sap_info?.LT_LISTA_CONTINGENTES" clean>
            <fw-heading class="mb-2">Dados de SAP</fw-heading>
            <b-collapse v-if="data.sap_info?.LT_LISTA_AUSENCIAS" class="card" animation="slide" :open="false">
              <template #trigger="props">
                <div class="card-header" role="button" :aria-expanded="props.open">
                  <p class="card-header-title">
                    Lista de Ausências
                  </p>
                  <a class="card-header-icon">
                    <fw-icon-chevron-up v-if="props.open" class="w-5 h-5" />
                    <fw-icon-chevron-down v-else class="w-5 h-5 " />
                  </a>
                </div>
              </template>
              <div class="card-content">
                <div class="content flex flex-col gap-2 ">
                  <template v-for="(item, index) in data.sap_info.LT_LISTA_AUSENCIAS">
                    <div
                      v-if="['3416', '3330', '3640', '3630', '9998'].includes(item.CODIGO_AUSENCIA)"
                      :key="`CODIGO_AUSENCIA-${index}`"
                      class="inline-flex gap-2 items-center border-b border-gray-100 w-full py-1"
                    >
                      <fw-tag type="xlight">{{ item.CODIGO_AUSENCIA }}</fw-tag>
                      <div class="font-medium w-60">De {{ item.DATA_INICIO }} a {{ item.DATA_FIM }}</div>
                      <div>
                        Num de dias {{ item.CODIGO_AUSENCIA == '9998' ? item.DIAS_AUSEN_CAL : item.DIAS_UTEIS_AUSEN }}
                      </div>
                      <div class="ml-auto text-gray-500">
                        Contingente {{ item.NUM_CONTIGENTE }} do ano {{ item.ANO_CONTIGENTE }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </b-collapse>
            <b-collapse v-if="data.sap_info?.LT_LISTA_CONTINGENTES" class="card" animation="slide" :open="false">
              <template #trigger="props">
                <div class="card-header" role="button" :aria-expanded="props.open">
                  <p class="card-header-title">
                    Lista de Contingentes
                  </p>
                  <a class="card-header-icon">
                    <fw-icon-chevron-up v-if="props.open" class="w-5 h-5" />
                    <fw-icon-chevron-down v-else class="w-5 h-5 " />
                  </a>
                </div>
              </template>
              <div class="card-content">
                <div class="flex flex-col gap-2 content">
                  <template v-for="(item, index) in data.sap_info.LT_LISTA_CONTINGENTES">
                    <div
                      v-if="[15, 97, 49, 50].includes(item.CONTINGENTE)"
                      :key="index"
                      class="flex flex-col border-b border-gray-100 w-full py-1"
                    >
                      <div class="inline-flex gap-2 items-center w-full">
                        <div class="font-medium mr-2">Contingente {{ item.CONTINGENTE }} do ano {{ item.ANO }}</div>
                        <div class="flex-1">dedutivel de {{ item.DATA_INI }} a {{ item.DEDUTIVEL_ATE }}</div>
                        <div class=" text-gray-500 w-28">Direito: {{ item.NUM_DIREITO }}</div>
                        <div class=" text-gray-500 w-32">Consumido: {{ item.NUM_CONSUMIDO }}</div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </b-collapse>
          </fw-panel-info>
        </div>

        <div class="text-xs text-gray-500 mt-4">
          <p>Códigos de ausencia:</p>
          <p>3416 - Justificação Crédito Anual</p>
          <p>3330 - Férias</p>
          <p>3640 - Falta por conta de férias do próprio ano</p>
          <p>3630 - Falta por conta de férias do ano seguinte</p>
          <p>9998 - Compensações</p>
        </div>
        <fw-panel-info label="Data (raw)" class="mt-4">
          <json-viewer :value="{ ...data, permissions }"></json-viewer>
        </fw-panel-info>
      </div>
    </b-modal>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  props: {
    userKey: {
      type: String,
      required: true,
    },
    mapKey: {
      type: String,
      required: true,
    },
    mapUserKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      active: false,
      loading: true,
      data: {},
    }
  },
  computed: {
    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    user() {
      return this.$store.getters.getUser
    },
    permissions() {
      return this.$store.getters.userPermissions
    },
    api() {
      return this.$store.state.api.base
    },
  },

  methods: {
    async openModal(skipCache = false) {
      this.loading = true
      this.active = true

      console.log(this.mapKey, this.mapUserKey, this.userKey)

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getManageUserMapDebug(this.mapKey, this.mapUserKey, this.userKey, skipCache)
        console.log('debug data :>> ', response)
        this.data = response
      })

      this.loading = false
    },
  },
}
</script>
