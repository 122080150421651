<template>
  <fw-layout
    full
    management
    :back-to="`/manage/vacations/${map.year}/maps`"
    main-content-max-width-on-full="md"
    :loading="loadingInitial"
    loading-icon="calendar"
    loading-title="Mapa de férias"
    right-sidebar-width="w-80 min-w-80"
    main-sidebar-width="w-64 min-w-64"
    :notfound="notFound"
  >
    <template v-if="map?.key && userMap?.key" #header-nav>
      <div class="flex flex-col max-w-lg">
        <v-clamp class="font-semibold text-base" autoresize :max-lines="1">{{
          users[userMap?.user_key]?.full_name
        }}</v-clamp>
        <fw-label class="hidden md:flex" marginless>Mapa de férias {{ map.year ?? '' }}</fw-label>
      </div>
    </template>

    <template #main-sidebar>
      <SidebarManageUserMaps ref="sidebarList" :year="map.year" :user-key="userMap.user_key" @open="gotToUserMap">
        <fw-button
          v-if="validations?.can_create_version && isAnyManager"
          type="light-primary"
          expanded
          @click.native="openCreateVersionModal"
          >Criar nova versão</fw-button
        >
      </SidebarManageUserMaps>
    </template>

    <template #main-content>
      <fw-panel-info v-if="userMap.blocked" type="orange" boxed custom-class="flex gap-3" class="mb-5">
        <div class="flex-1 flex flex-col gap-2">
          <div class="flex gap-3 items-center">
            <div class="relative">
              <fw-icon-settings class="w-6 h-6 flex-shrink-0" />
              <span v-if="userMap.blocked === true" class="-top-1 -right-1.5 absolute">
                <fw-dot color="orange" />
              </span>
            </div>
            <div class="flex-1 font-semibold text-base">Modo gestão ativo</div>
          </div>
          <div class="text-xs">
            Este mapa foi colocado em modo de gestão. Neste modo, o trabalhador não poderá realizar alterações.
          </div>
        </div>
      </fw-panel-info>

      <PanelManageCurrentUserMap
        v-if="isCurrentMap"
        :loading="loading"
        :calendar-attributes="calendarAttributes"
        :map="map"
        :map-is-closed="mapIsClosed"
        :user-map="userMap"
        :validations="validations"
        :year-activity="yearActivity"
        :map-user-days="mapUserDays"
        :current-working-version="currentWorkingVersion"
      />
      <PanelManageUserMap
        v-else
        :map="map"
        :loading="loading"
        :saving-data="savingData"
        :edit-mode="editMode"
        :user-map="userMap"
        :validations="validations"
        :map-is-closed="mapIsClosed"
        :year-activity="yearActivity"
        :map-user-days="mapUserDays"
        :can-add-partial-day="canAddPartialDay"
        :current-version-motive-days-count="currentVersionMotiveDaysCount"
        @open-modal-add-days="openModalAddDays"
        @update-day="editDay"
        @delete-day="deleteDay"
        @accumulate-day="accumulateDay"
        @accumulate-remaining="confirmAccumulateRemaining"
      />
    </template>

    <template #right-sidebar>
      <ContentLoader v-if="loading" type="sidebar"></ContentLoader>
      <div v-else-if="map?.key && userMap?.key" class="px-5 py-3 flex-1 flex flex-col gap-5">
        <div>
          <fw-label>Estado da versão do mapa</fw-label>
          <fw-tag
            size="lg"
            expanded
            :type="colors[userMap.status]"
            custom-class="px-3 flex items-center justify-center gap-2"
          >
            <span>{{ statusLabels[userMap.status]?.['pt'] }}</span>
          </fw-tag>
          <fw-panel-info v-if="userMap?.status === 'outdated'" size="xs" clean icon class="my-2">
            Quando uma nova versão é criada, as anteriores são automaticamente arquivadas.
          </fw-panel-info>
          <fw-panel-info v-if="userMap?.status === 'submitted'" size="xs" type="primary" clean icon class="my-2">
            Aguarda aprovação por parte do responsável pelo mapa.
          </fw-panel-info>
        </div>

        <div
          v-if="validations.can_approve || validations.can_pre_approve || validations.can_decline"
          class="border-t pt-3"
        >
          <fw-label>Decisão</fw-label>
          <div class="grid grid-cols-2 gap-5">
            <div :class="{ 'col-span-2': !validations.can_decline }">
              <fw-button
                v-if="validations.can_approve || validations.can_pre_approve"
                :type="'border-primary'"
                expanded
                class="h-full px-4"
                @click.native="openModalDecision('approve')"
                >{{ newStatus == 'pre_approved' ? 'Dar parecer' : 'Aprovar' }}</fw-button
              >
            </div>

            <div :class="{ 'col-span-2': !validations.can_approve && !validations.can_pre_approve }">
              <fw-button
                v-if="validations.can_decline"
                :type="'border-danger'"
                expanded
                class="h-full px-4"
                @click.native="openModalDecision('declined')"
                >Rejeitar</fw-button
              >
            </div>
          </div>
        </div>

        <div v-if="isAnyManager" class="border-b pb-4">
          <div>
            <fw-label>Operações de gestão</fw-label>
          </div>

          <div v-if="!isCurrentMap && (hasAvailableActions || isDraft)" class="mb-2 flex flex-col gap-3">
            <div v-if="editMode" class="flex gap-2 mt-2">
              <div v-if="isDraft || canSubmitVersion" class="flex-grow">
                <fw-button
                  :type="canSubmitVersion ? 'primary' : 'border-light'"
                  :disabled="!canSubmitVersion || savingData"
                  expanded
                  @click.native="submitMapUser"
                  >Submeter</fw-button
                >
              </div>
              <div v-if="validations?.can_cancel" class="flex-grow">
                <fw-button :type="'border-light'" expanded :disabled="savingData" @click.native="cancelMapUser"
                  >Cancelar</fw-button
                >
              </div>
            </div>
            <div v-if="userMap?.status != 'canceled'">
              <fw-button
                :type="!editMode ? 'border-orange' : editMode ? 'orange' : 'border-light'"
                expanded
                :loading="savingData"
                :disabled="savingData"
                @click.native="toggleEditMode()"
              >
                {{ editMode ? 'Fechar modo edição' : 'Editar mapa' }}
              </fw-button>
            </div>
          </div>

          <div v-if="permissions.isManager || permissions.isAdmin">
            <div>
              <fw-button
                :type="!canSyncWorkerRights ? 'link-muted' : 'link'"
                :disabled="!canSyncWorkerRights"
                icon="arrow-right"
                @click.native="confirmSyncWorkerRights"
              >
                Sincronizar direitos</fw-button
              >
            </div>
            <RecordManageMapSyncJob
              v-if="workerRightsMapSyncs?.length"
              :sync-job="workerRightsMapSyncs?.[0]"
              context="sync-rights"
              type="card"
            />
          </div>

          <div v-if="permissions.isManager || permissions.isAdmin">
            <div>
              <fw-button
                :type="!canSyncMyUCDays || userMap.status != 'approved' ? 'link-muted' : 'link'"
                :disabled="!canSyncMyUCDays || userMap.status != 'approved'"
                icon="arrow-right"
                @click.native="confirmSendMyUCDaysToSap"
              >
                Sincronizar dias para SAP
              </fw-button>
              <fw-panel-info v-if="userMap.status != 'approved'" clean icon size="xs" class="-mt-1.5 ml-10">
                Apenas disponível em versões aprovadas
              </fw-panel-info>
            </div>
            <RecordManageMapSyncJob
              v-if="myucDaysMapSyncs?.length"
              :sync-job="myucDaysMapSyncs?.[0]"
              context="sync-days"
              type="card"
            />
          </div>
        </div>

        <BlockUserMapRules
          v-if="userMap?.version_motive != 'credit_days'"
          :periods="periods"
          :contingentes="userMap.contingentes"
          :year="map.year"
        />
        <BlockUserMapDetails :user-map="userMap" :map="map" :users="users" />

        <BlockSapDebug :user-key="userKey" :map-key="mapKey" :map-user-key="userMap.key"> </BlockSapDebug>
      </div>
    </template>

    <template #modals>
      <fw-modal
        :active.sync="modalCreateVersionOpen"
        size="sm"
        :title="'Criar versão'"
        description="Indique o motivo da criação da nova versão do mapa de férias."
        @close="closeCreateVersionModal"
      >
        <ModalCreateVersion
          :map-key="map.key"
          :map-year="map.year"
          :manager="true"
          :user-key="userMap.user_key"
          :can-create-version="{
            vacations: canCreateVacationsVersion,
            credit_days: Boolean(canCreateCreditDaysVersion),
            accumulate_days: Boolean(canCreateCumulateDaysVersion),
          }"
          @close="closeCreateVersionModal"
          @created="createdVersion"
        />
      </fw-modal>
      <fw-modal
        :active.sync="modalAddDaysOpen"
        :can-cancel="true"
        size="full"
        width="54rem"
        boxed="sm"
        @close="closeModalAddDays"
      >
        <template #default>
          <ModalAddVacationsMapDays
            :unselectable-dates="unselectableDates"
            :excluded-weekdays="calendarExcludedWeekdays"
            :days-count="currentVersionMotiveDaysCount"
            :can-add-partial-day="canAddPartialDay"
            :is-creditdays="userMap?.version_motive == 'credit_days'"
            :events="events"
            :year="map.year"
            @close="closeModalAddDays"
            @add="addDays"
          />
        </template>
      </fw-modal>
      <fw-modal :active.sync="modalDecisionOpen" boxed="sm" size="3xl" width="42rem" @close="closeModalDecision">
        <ModalMessage
          v-if="modalDecisionOpen"
          :title="currentModalDecision == 'declined' ? 'Rejeitar versão do mapa' : 'Aprovar versão do mapa'"
          :description="
            currentModalDecision == 'declined'
              ? 'Indique a fundamentação da rejeição, que será visualizada pelo trabalhador.'
              : newStatus == 'approved'
              ? 'Se desejar, escreva uma mensagem para o trabalhador.'
              : newStatus == 'pre_approved'
              ? `Escreva uma mensagem de fundamentação de dar parecer, que será visualizada pelo trabalhador.`
              : null
          "
          :required-description="currentModalDecision == 'declined'"
          :ignore-confirm-public-message="true"
          :allow-files="false"
          :show-public-private-toggle="false"
          @close="closeModalDecision"
          @save="saveDecision"
        />
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import BlockSapDebug from '@/components/blocks/BlockSapDebug'
import ModalMessage from '@/fw-modules/fw-core-vue/ui/components/modals/ModalMessage'
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'
import PanelManageUserMap from '@/components/panels/manage/PanelManageUserMap'
import SidebarManageUserMaps from '@/components/sidebars/manage/SidebarManageUserMaps'
import BlockUserMapDetails from '@/components/blocks/BlockUserMapDetails'
import BlockUserMapRules from '@/components/blocks/BlockUserMapRules'
import PanelManageCurrentUserMap from '@/components/panels/manage/PanelManageCurrentUserMap'
import RecordManageMapSyncJob from '@/components/records/manage/RecordManageMapSyncJob'
import ModalAddVacationsMapDays from '@/components/modals/ModalAddVacationsMapDays'
import ModalCreateVersion from '@/components/modals/ModalCreateVersion'
import difference from 'lodash/difference'
import {
  MAP_USER_STATUS,
  MAP_USER_STATUS_COLORS,
  FULL_DAY_TYPES,
  PARTIAL_DAY_TYPES,
  MAP_USER_VERSION_REASONS,
  VACATIONS_DAY_TYPES,
  CREDIT_DAY_TYPES,
} from '@/utils/index.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    PanelManageUserMap,
    BlockUserMapRules,
    PanelManageCurrentUserMap,
    BlockUserMapDetails,
    SidebarManageUserMaps,
    ContentLoader,
    ModalMessage,
    RecordManageMapSyncJob,
    ModalAddVacationsMapDays,
    ModalCreateVersion,
    BlockSapDebug,
  },

  data() {
    return {
      loadingInitial: true,
      colors: Object.freeze(MAP_USER_STATUS_COLORS),
      statusLabels: Object.freeze(MAP_USER_STATUS),
      vacationDayTypes: Object.freeze(VACATIONS_DAY_TYPES),
      creditDayTypes: Object.freeze(CREDIT_DAY_TYPES),
      fullDayTypes: Object.freeze(FULL_DAY_TYPES),
      partialDayTypes: Object.freeze(PARTIAL_DAY_TYPES),
      versionsMotives: Object.freeze(MAP_USER_VERSION_REASONS),

      loading: false,
      savingData: false,

      map: {},
      userMap: {},
      mapUserDays: [],
      uniqueMapUserDays: [], // To have the date only with the last sync_operation
      periods: [],

      users: {},
      validations: {
        can_approve: false,
        can_pre_approve: false,
        can_decline: false,
        can_add_message: true,
        can_edit_days: false,
        can_add_days: false,
        can_submit: false,
        can_cancel: false,
        can_create_version: false,
      },

      allYears: [],

      isMapManager: false,
      isMapCreditdaysManager: false,
      isMapApprover: false,
      isMapCreditdaysApprover: false,
      isMapReporter: false,
      isMapGlobalReporter: false,

      modalCreateVersionOpen: false,
      modalDecisionOpen: false,
      currentModalDecision: null,
      modalAddDaysOpen: false,

      // creditDays: [],
      // partialCreditDays: [],
      // notEditableSameYearDays: [],
      // partialNotEditableSameYearDays: [],
      // fullDays: [],
      // partialDays: [],
      // accumulatedDays: [],
      // accumulatedPartialDays: [],
      calendarDisableDates: [],
      calendarAllowedDates: [],
      invalidDates: [],
      holidays: [],
      calendarAttributes: [],
      latestApproved: {},
      latestVersions: {},

      syncs: {
        maps_permissions: [],
        myuc_days: [],
        populate_year_maps: [],
        worker_rights: [],
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    loggedUser() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language
    },

    user() {
      return this.users[this.userMap?.user_key]
    },

    mapKey() {
      return this.$route.params.key
    },

    mapUserKey() {
      return this.$route.params.mapUserKey
    },

    permissions() {
      return this.$store.getters.userPermissions
    },

    editMode() {
      return this.userMap.blocked === true
    },

    allValidRules() {
      return this.requiredRules?.every(el => el.is_valid)
    },

    allValidContingentes() {
      return !this.userMap?.contingentes?.length || this.userMap?.contingentes?.every(el => el.is_valid)
    },

    hasChanges() {
      return this.mapUserDays?.some(el => el.is_from_previous_map == false)
    },

    hasPartialDays() {
      console.log('this.uniqueMapUserDays', this.uniqueMapUserDays)
      return this.uniqueMapUserDays?.some(el => PARTIAL_DAY_TYPES.includes(el.type))
    },

    canAddPartialDay() {
      // version_motive: 'credit_days','vacations','accumulate_days', 'sap_sync', 'starter_map'
      if (this.userMap.version_motive == 'credit_days') return true
      return (
        this.currentVersionMotiveDaysCount.remaining == -0.5 ||
        (this.currentVersionMotiveDaysCount.total % 1 != 0 &&
          this.currentVersionMotiveDaysCount.remaining <= 0.5 &&
          !this.hasPartialDays)
      )
    },

    canSubmitVersion() {
      console.log('this.currentVersionMotiveDaysCount', this.currentVersionMotiveDaysCount)
      if (this.userMap.version_motive == 'credit_days') {
        return (
          this.validations?.can_submit &&
          this.hasChanges &&
          this.userMap.days_count.credit_selected <= this.userMap.days_count.csv_credit
        )
      } else if (
        ['starter_map', 'vacations', 'sap_sync'].includes(this.userMap.version_motive) ||
        this.userMap?.is_sap_sync
      ) {
        if (this.permissions.isManager || this.permissions.isAdmin) {
          if (this.userMap.version_motive == 'sap_sync' || this.userMap?.is_sap_sync)
            return this.validations?.can_submit //&& this.currentVersionMotiveDaysCount?.remaining == 0
          return this.validations?.can_submit && this.hasChanges //&& this.currentVersionMotiveDaysCount?.remaining == 0
        }

        if (this.userMap.version_motive == 'sap_sync' || this.userMap?.is_sap_sync) {
          return (
            this.validations?.can_submit &&
            this.allValidRules &&
            this.allValidContingentes &&
            this.currentVersionMotiveDaysCount?.remaining == 0
          )
        }

        return (
          this.validations?.can_submit &&
          this.hasChanges &&
          this.allValidRules &&
          this.allValidContingentes &&
          this.currentVersionMotiveDaysCount?.remaining == 0
        )
      }

      return this.validations?.can_submit && this.hasChanges
    },

    currentVersionMotiveDaysCount() {
      // csv_total:22
      // csv_compensated:0
      // csv_credit:0
      // credit_selected:0
      // credit_remaining:0
      // total:22
      // selected:4
      // accumulated:4
      // remaining:18
      if (!this.userMap?.key)
        return {
          total: 0,
          selected: 0,
          remaining: 0,
        }

      if (this.userMap.version_motive == 'credit_days')
        return {
          total: this.userMap.days_count.csv_credit,
          selected: this.userMap.days_count.credit_selected,
          remaining: this.userMap.days_count.credit_remaining,
        }

      return {
        total: this.userMap.days_count.total,
        selected: this.userMap.days_count.selected,
        remaining: this.userMap.days_count.remaining,
      }
    },

    notFound() {
      return !this.loading && !this.loadingInitial && !this.userMap?.key
    },

    canCreateVacationsVersion() {
      return Boolean(
        this.validations?.can_create_version &&
          (this.userMap.year == new Date().getFullYear() || this.userMap.year == new Date().getFullYear() - 1)
      )
    },

    canCreateCreditDaysVersion() {
      return this.validations?.can_create_version && this.userMap.days_count?.csv_credit
    },

    canCreateCumulateDaysVersion() {
      return (
        this.validations?.can_create_version &&
        (this.map?.year < new Date().getFullYear() ||
          (this.map?.year == new Date().getFullYear() && new Date().getMonth() == 12))
      )
    },

    hasAvailableActions() {
      return !this.isCurrentMap && (this.canSubmitVersion || this.validations?.can_cancel)
    },

    calendarExcludedWeekdays() {
      // Convert strings to new array with allowed weekdays
      let excludedWeekdays = [0, 6]
      if (this.userMap.allowed_weekdays?.length) {
        const allowedWeekdays = this.userMap.allowed_weekdays.map(day => {
          // Backend is returning day 1 as monday and we need to have as sunday
          return parseInt(day)
        })
        excludedWeekdays = difference([0, 1, 2, 3, 4, 5, 6], allowedWeekdays)
      }
      console.log('excludeWeekdays', excludedWeekdays)
      return excludedWeekdays
    },

    unselectableDates() {
      return [...new Set(this.invalidDates.concat(this.currentMapDays))]
    },

    events() {
      const events = {}

      for (const day of this.currentMapDays) {
        events[day] = {
          date: new Date(`${day}T00:00:00`),
          type: 'is-success',
        }
      }
      for (const day of this.invalidDates) {
        events[day] = {
          date: new Date(`${day}T00:00:00`),
          type: 'is-warning',
        }
      }

      return Object.values(events)
    },

    isCurrentMap() {
      return this.$route.params.mapUserKey == 'current'
    },

    isStarterMap() {
      return this.userMap?.version_motive == 'starter_map'
    },

    isDraft() {
      return this.userMap?.status == 'draft'
    },

    year() {
      return Number(this.$route.params.year)
    },

    userKey() {
      return this.$route.params.userKey
    },

    mapIsClosed() {
      return this.map?.status == 'closed'
    },

    yearActivity() {
      let colors = {}
      let icons = {}
      let days = {}
      let uniqueMapUserDays = {}

      for (const day of this.holidays) {
        const dateFormatted = Dates.format(day.date, 'DD/MM/YYYY')
        days[dateFormatted] = day.title
        colors[dateFormatted] = 'bg-gray-300'
      }

      if (this.userMap?.birthday) {
        const dateFormatted = Dates.format(this.userMap?.birthday, 'DD/MM/YYYY')
        days[dateFormatted] = 'Aniversário'
        colors[dateFormatted] = 'bg-lime-500 bg-opacity-80 text-white'
        icons[dateFormatted] = 'start-smile-solid'
      }

      if (!this.mapUserDays?.length)
        return {
          data: days,
          colors,
          icons,
        }

      if (this.isCurrentMap) {
        uniqueMapUserDays = this.uniqueMapUserDays
      } else {
        for (const day of this.mapUserDays) {
          if (!uniqueMapUserDays[day.date]) {
            uniqueMapUserDays[day.date] = day
          } else {
            delete uniqueMapUserDays[day.date]
            uniqueMapUserDays[day.date] = day
          }
        }
      }

      for (const day of this.uniqueMapUserDays) {
        if (day.sync_operation != 'del') {
          const dateFormatted = Dates.format(day.date, 'DD/MM/YYYY')
          if (CREDIT_DAY_TYPES.includes(day.type)) {
            days[dateFormatted] = FULL_DAY_TYPES.includes(day.type) ? 1 : 0.5
            colors[dateFormatted] = 'text-white'
            icons[dateFormatted] = 'record-circle'
          } else {
            days[dateFormatted] = FULL_DAY_TYPES.includes(day.type) ? 1 : 0.5
          }
        }
      }

      return {
        data: days,
        colors,
        icons,
      }
    },

    newStatus() {
      let status = 'approved' // only manager can approve

      if (this.userMap.version_motive == 'credit_days') {
        if (this.isMapCreditdaysApprover && this.userMap.status == 'submitted') {
          status = 'pre_approved'
        }
      } else if (this.isMapApprover && this.userMap.status == 'submitted') {
        status = 'pre_approved'
      }

      return status
    },

    workerRightsMapSyncs() {
      return this.syncs.worker_rights?.filter(
        el => el.params.employee_numbers.includes(this.user.number) || !el.params.employee_numbers?.length
      )
    },

    canSyncWorkerRights() {
      return this.syncs.worker_rights?.length == 0 || this.workerRightsMapSyncs?.every(el => el.ended_at)
    },

    myucDaysMapSyncs() {
      return this.syncs.myuc_days?.filter(
        el => el.params.employee_numbers.includes(this.user.number) || !el.params.employee_numbers?.length
      )
    },

    canSyncMyUCDays() {
      return this.syncs.myuc_days?.length == 0 || this.myucDaysMapSyncs?.every(el => el.ended_at)
    },

    isAnyManager() {
      return this.permissions.isMapsManager || this.permissions.isManager || this.permissions.isAdmin
    },
  },

  watch: {
    async mapUserKey(newVal) {
      if (newVal) {
        if (this.isCurrentMap) {
          await this.getActiveUserMaps()
          await this.getMap()
        } else {
          this.getMap()
        }
      }
    },

    year() {
      if (!this.year || this.year <= 1900 || isNaN(this.year)) {
        console.log('Invalid year', this.year)
        return this.$router.push({ name: 'manage' })
      }
    },
  },

  async mounted() {
    if (!this.year || this.year <= 1900 || isNaN(this.year)) {
      console.log('Invalid year', this.year)
      return this.$router.push({ name: 'manage-vacations' })
    }

    if (this.isCurrentMap) {
      await this.getActiveUserMaps()
      await this.getMap()
    } else {
      await Promise.all([this.getMap(), this.getActiveUserMaps()])
    }
    this.getSyncs()
    this.loadingInitial = false
  },

  methods: {
    async toggleEditMode() {
      this.savingData = true
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.updatedManagerMapUserBlocked(
          this.mapKey,
          this.mapUserKey,
          this.userKey,
          !this.editMode
        )
        this.userMap.blocked = response.user_map.blocked

        this.validations.can_edit_days = response.permissions.can_edit_days
        this.validations.can_add_days = response.permissions.can_add_days
      })
      this.savingData = false
    },

    closeCreateTaskModal() {
      this.isCreateTaskModalActive = false
    },

    openCreateVersionModal() {
      if (!this.validations?.can_create_version) return

      this.modalCreateVersionOpen = true
    },

    closeCreateVersionModal() {
      this.modalCreateVersionOpen = false
    },

    closeModalAddDays() {
      this.modalAddDaysOpen = false
    },

    openModalAddDays() {
      this.modalAddDaysOpen = true
    },

    async addDays(days) {
      if (!this.validations.can_add_days) {
        console.warn('can_add_days ', this.validations.can_add_days)
        return
      }

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.addManageUserMapDays(this.mapKey, this.mapUserKey, this.userKey, days)
        console.log('addDay :>> ', response)
        this.setMapData(response)

        this.closeModalAddDays()

        this.$buefy.snackbar.open({
          message: `Os dias foram adicionados ao mapa.`,
          type: 'is-primary',
        })
      })
    },

    createdVersion() {
      this.modalCreateVersionOpen = false
      this.$refs.sidebarList.getUserMaps()
    },

    async getSyncs() {
      if (!this.permissions.isManager || !this.permissions.isAdmin) return
      await utils.tryAndCatch(this, async () => {
        const res = await this.api.getSyncs(this.year)
        this.syncs = res
      })
    },

    confirmSyncWorkerRights() {
      console.log('this.user', this.user)
      this.$buefy.dialog.confirm({
        cancelText: 'Cancelar',
        confirmText: 'Sincronizar',
        title: 'Sincronizar direitos do trabalhador',
        message: `Tem a certeza que deseja continuar? Esta sincronização poderá resultar na criação de novas versões dos mapas.`,
        onConfirm: async () => {
          const syncData = await this.api.syncWorkerRights(this.year, [this.user.number])
          this.syncs.worker_rights.push(syncData)
        },
      })
    },

    confirmSendMyUCDaysToSap() {
      this.$buefy.dialog.confirm({
        cancelText: 'Cancelar',
        confirmText: 'Sincronizar',
        title: 'Sincronizar dias de férias para SAP',
        message: `Tem a certeza que deseja continuar?`,
        onConfirm: async () => {
          const syncData = await this.api.syncMyUCdays(this.year, [this.user.number])
          this.syncs.myuc_days.push(syncData)
        },
      })
    },

    openModalDecision(decision) {
      this.currentModalDecision = decision
      this.modalDecisionOpen = true
    },

    closeModalDecision() {
      this.currentModalDecision = null
      this.modalDecisionOpen = false
    },

    saveDecision(message) {
      console.log('saveDecision :>> ', message)
      if (this.currentModalDecision == 'declined') {
        this.confirmReject(message.description)
      } else {
        this.confirmApprove(message.description)
      }
    },

    confirmApprove(message) {
      let confirmMeta = {
        approved: {
          title: 'Aprovar versão do mapa',
          message: `Tem a certeza que deseja continuar? Ao aprovar o mapa, valida o mapa de férias
          submetido pelo trabalhador. Existindo essa necessidade, o trabalhador poderá criar uma nova versão
          do mapa com vista à alteração dos dias aprovados.`,
          confirmText: 'Aprovar',
        },
        pre_approved: {
          title: 'Dar parecer da versão do mapa',
          message: `Tem a certeza que pretende dar parecer no mapa do trabalhador? Ao avançar, o mesmo será enviado
          para aprovação. Enquanto decorrer o processo de aprovação, o trabalhador não conseguirá criar novas versões do mapa.`,
          confirmText: 'Dar parecer',
        },
      }

      this.$buefy.dialog.confirm({
        title: confirmMeta[this.newStatus].title,
        message: confirmMeta[this.newStatus].message,
        confirmText: confirmMeta[this.newStatus].confirmText,
        onConfirm: async () => {
          this.changeStatus(this.newStatus, message)
          this.closeModalDecision()
        },
      })
    },

    confirmReject(message) {
      this.$buefy.dialog.confirm({
        title: 'Rejeitar versão do mapa',
        message: `Tem a certeza que deseja rejeitar a versão do mapa?`,
        confirmText: 'Rejeitar',
        type: 'is-danger',
        onConfirm: async () => {
          this.changeStatus('declined', message)
          this.closeModalDecision()
        },
      })
    },

    async getActiveUserMaps() {
      console.log('getActiveUserMaps', this.userMap)
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getManagerMapUsers(this.mapKey, {
          user_key: this.userKey,
          latest_only: true,
          get_latest_versions: true,
          year: this.year,
        })
        console.log('getActiveUserMaps :>> ', response)

        this.latestApproved = response.latest_versions[this.year]
        this.latestVersions = response.latest_versions
      })
    },

    gotToUserMap(item, current = null) {
      console.log('manager gotToUserMap', item, current)
      if (current) {
        this.$router.push({
          name: 'manage-map-user',
          params: { userKey: item.user_key, year: this.year, key: item.map_key, mapUserKey: current },
        })
      } else {
        this.$router.push({
          name: 'manage-map-user',
          params: { userKey: item.user_key, year: this.year, key: item.map_key, mapUserKey: item.key },
        })
      }
    },

    setMapData(response) {
      console.log('setMapData', response)
      this.currentWorkingVersion = response.working_version
      this.userMap = response.user_map
      this.map = response.map
      this.mapUserDays = response.map_user_days // !WARN! must be ordered by day and created date
      this.setUniqueDays()
      this.periods = response.periods
      this.validations = response.permissions
      this.isMapManager = response.roles?.is_map_manager
      this.isMapCreditdaysManager = response.roles?.is_map_creditdays_manager
      this.isMapApprover = response.roles?.is_map_approver
      this.isMapCreditdaysApprover = response.roles?.is_map_creditdays_approver
      this.isMapReporter = response.roles?.is_map_reporter
      this.isMapGlobalReporter = response.roles?.is_map_global_reporter
      this.users = { ...this.users, ...response.users }

      this.setDisabledDates()
      this.setSelectedDays()
    },

    async getMap() {
      console.log('getManagerMapUser')
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        let mapUserKey = this.mapUserKey
        if (this.isCurrentMap) {
          mapUserKey = this.latestApproved.key
        }
        if (!mapUserKey) return
        const response = await this.api.getManagerMapUser(this.mapKey, mapUserKey, this.userKey)
        console.log('getManagerMapUser', response)
        this.setMapData(response)
      })

      this.loading = false
    },

    setUniqueDays() {
      const uniqueMapUserDays = {}
      for (const day of this.mapUserDays) {
        if (!uniqueMapUserDays[day.date]) {
          uniqueMapUserDays[day.date] = day
        } else {
          delete uniqueMapUserDays[day.date]
          uniqueMapUserDays[day.date] = day
        }
      }

      this.uniqueMapUserDays = Object.values(uniqueMapUserDays)
    },

    setSelectedDays() {
      console.log('setSelectedDays')
      // this.creditDays = []
      // this.partialCreditDays = []
      // this.notEditableSameYearDays = []
      // this.partialNotEditableSameYearDays = []
      // this.fullDays = []
      // this.partialDays = []
      // this.accumulatedDays = []
      // this.accumulatedPartialDays = []
      this.currentMapDays = []

      for (let index = 0; index < this.uniqueMapUserDays.length; index++) {
        const day = this.uniqueMapUserDays[index]

        // VACATIONS_MOTIVE = "vacations"
        // CREDIT_DAYS_MOTIVE = "credit_days"
        // ACCUMULATE_DAYS_MOTIVE = "accumulate_days"
        // SAP_SYNC_MOTIVE = "sap_sync"
        // STARTER_MAP_MOTIVE = "starter_map"

        if (day.type.startsWith('creditday')) {
          if (day.sync_operation != 'del') {
            this.currentMapDays.push(day.date)
            this.calendarAttributes.push({
              key: day.key,
              customData: {
                title: day.type == 'creditday' ? 'Dia crédito' : `Crédito (${this.$t('types.' + day.type)})`,
                details:
                  day.type == 'creditday' ? 'Crédito' : `Dia de crédito parcial (${this.$t('types.' + day.type)})`,
                class: 'bg-primary text-white',
                type: day.type,
                entry: day,
              },

              dates: new Date(`${day.date}T00:00:00`),
            })
          }
        } else if (day.type == 'allday') {
          if (!day.origin_allows_edit) {
            if (new Date(day.date).getFullYear() == this.map.year) {
              // this.notEditableSameYearDays.push(day.date)
              this.currentMapDays.push(day.date)
              this.calendarAttributes.push({
                key: day.key,
                customData: {
                  title: 'Não editável',
                  details: `Dia não editável (${day.sync_message})`,
                  class: 'bg-yellow-600 bg-opacity-10 text-yellow-600',
                  type: day.type,
                  entry: day,
                },
                dates: new Date(`${day.date}T00:00:00`),
              })
            }
          } else if (day.sync_operation == 'add') {
            // this.fullDays.push(day.date)
            this.currentMapDays.push(day.date)
            this.calendarAttributes.push({
              key: day.key,
              customData: {
                title: 'Férias',
                details: 'Dia inteiro de férias',
                class: 'bg-primary text-white',
                type: day.type,
                entry: day,
              },
              dates: new Date(`${day.date}T00:00:00`),
            })
          } else if (day.sync_operation == 'acc') {
            // this.accumulatedDays.push(day.date)
            this.currentMapDays.push(day.date)
            this.calendarAttributes.push({
              key: day.key,
              customData: {
                title: 'Cumulado',
                details: 'Dia inteiro cumulado',
                class: 'bg-yellow-600 bg-opacity-10 text-yellow-700',
                type: day.type,
                entry: day,
              },
              dates: new Date(`${day.date}T00:00:00`),
            })
          }
        } else {
          if (!day.origin_allows_edit) {
            if (new Date(day.date).getFullYear() == this.map.year) {
              // this.partialNotEditableSameYearDays.push(day.date)
              this.currentMapDays.push(day.date)
              this.calendarAttributes.push({
                key: day.key,
                customData: {
                  title: `Não editável (${this.$t('types.' + day.type)})`,
                  details: `Dia parcial não editável ${day.type} (${day.sync_message})`,
                  class: 'bg-yellow-600 bg-opacity-10 text-yellow-600',
                  type: day.type,
                  entry: day,
                },
                dates: new Date(`${day.date}T00:00:00`),
              })
            }
          } else if (day.sync_operation == 'acc') {
            // this.accumulatedPartialDays.push(day.date)
            this.currentMapDays.push(day.date)
            this.calendarAttributes.push({
              key: day.key,
              customData: {
                title: `Cumulado (${this.$t('types.' + day.type)})`,
                details: `Dia cumulado parcial (${this.$t('types.' + day.type)})`,
                class: 'bg-yellow-600 bg-opacity-10 text-yellow-700',
                type: day.type,
                entry: day,
              },
              dates: new Date(`${day.date}T00:00:00`),
            })
          } else {
            // this.partialDays.push(day.date)
            this.currentMapDays.push(day.date)
            this.calendarAttributes.push({
              key: day.key,
              customData: {
                title: `Férias (${this.$t('types.' + day.type)})`,
                details: `Dia de férias parcial (${this.$t('types.' + day.type)})`,
                class: 'bg-primary text-white',
                type: day.type,
                entry: day,
              },
              dates: new Date(`${day.date}T00:00:00`),
            })
          }
        }
      }
    },

    setDisabledDates() {
      console.log('setDisabledDates')
      this.requiredRules = []

      let blocked = []
      let allowed = []
      let holidays = []
      this.periods.forEach(period => {
        let blockedPeriodsAttributes = []
        if (period.type == 'holiday') {
          if (!holidays.includes(period.start_at)) holidays.push(period.start_at)
          this.invalidDates.push(period.start_at)
          this.holidays.push({
            title: period.title,
            date: period.start_at,
          })
          this.calendarAttributes.push({
            key: period.key,
            customData: {
              title: period.title,
              class: 'bg-gray-400 text-white',
              type: period.type,
              entry: period,
            },
            dates: {
              start: new Date(`${period.start_at}T00:00:00`),
              end: new Date(`${period.end_at}T00:00:00`),
            },
          })
        }

        // Rules required
        else if (period.type == 'required') {
          this.requiredRules.push(period)
        }

        // Resolve restantes dias (permitidos e bloqueados)
        else {
          const periodDays = Dates.getDatesFromRange(period.start_at, period.end_at).map(date =>
            Dates.buildCore(date).format('YYYY-MM-DD')
          )

          periodDays.forEach(periodDay => {
            // console.log('periodDay', periodDay)
            if (period.type == 'allowed') {
              if (!allowed.includes(periodDay)) allowed.push(periodDay)
            } else if (period.type == 'blocked') {
              if (!blocked.includes(periodDay)) blocked.push(periodDay)
              if (!blockedPeriodsAttributes.includes(periodDay)) blockedPeriodsAttributes.push(periodDay)
            }
          })

          if (period.type == 'blocked' && blockedPeriodsAttributes.length) {
            this.calendarAttributes.push({
              key: period.key,
              customData: {
                title: period.title,
                class: 'bg-gray-400 text-white',
                type: period.type,
                entry: period,
              },
              dates: blockedPeriodsAttributes,
            })
          }
        }
      })

      // Regista restantes dias - para bloquear
      blocked.forEach(day => {
        this.invalidDates.push(day)
      })

      // Adiciona nota no dia de aniversário
      if (this.userMap?.birthday) {
        this.invalidDates.push(this.userMap?.birthday)
        this.calendarAttributes.push({
          key: 'birthday',
          customData: {
            title: 'Parabéns! Este dia de dispensa é seu.',
            class: 'bg-teal-600 bg-opacity-10 text-teal-600',
            type: 'birthday',
          },
          dates: new Date(`${this.userMap.birthday}T00:00:00`),
        })
      }

      // console.log('this.requiredRules', this.requiredRules)
      // console.log('this.invalidDates', this.invalidDates)
    },

    async editDay(dayData) {
      console.log('editDay', dayData)
      if (!this.validations.can_edit_days) {
        console.warn('can_edit_days ', this.validations.can_edit_days)
        return this.$buefy.snackbar.open({
          message: `O dia ${dayData.date} não pode ser editado.`,
          type: 'is-warning',
        })
      }

      this.savingData = true
      const formattedDate = Dates.formatDateToAPI(dayData.date)
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.updateManageUserMapDays(this.mapKey, this.mapUserKey, this.userKey, [
          { date: formattedDate, type: dayData.type },
        ])
        console.log('editDay response :>> ', response)
        this.setMapData(response)
        this.$buefy.snackbar.open({
          message: `O registo de dia ${dayData.date} foi atualizado.`,
          type: 'is-primary',
        })
      })

      this.savingData = false
    },

    accumulateDay(day) {
      console.log('accumulateDay', day)
      if (this.userMap.version_motive != 'accumulate_days' || day.sync_operation != 'add') {
        console.error('Cant accumulateDay', day)
        return this.$buefy.snackbar.open({
          message: `O dia ${day.date} não pode ser cumulado.`,
          type: 'is-warning',
        })
      }
      this.editDay({ date: day.date, type: day.type ?? 'allday' })
    },

    confirmAccumulateRemaining() {
      this.$buefy.dialog.confirm({
        type: 'is-dark',
        title: 'Cumular dias restantes',
        message: `Tem a certeza que pretende cumular ${this.currentVersionMotiveDaysCount.remaining} dias?`,
        onConfirm: async () => {
          await this.accumulateRemaining()
        },
        confirmText: 'Cumular',
        cancelText: 'Fechar',
      })
    },

    async accumulateRemaining() {
      if (this.userMap.version_motive != 'accumulate_days' || !this.validations.can_edit_days) {
        return this.$buefy.snackbar.open({
          message: `Não podem ser cumulados os dias restantes.`,
          type: 'is-warning',
        })
      }

      if (this.currentVersionMotiveDaysCount.remaining <= 0) {
        return this.$buefy.snackbar.open({
          message: `Não tem dias para cumular.`,
          type: 'is-warning',
        })
      }

      this.savingData = true
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.cumulateRemainingManageUserMapDays(this.mapKey, this.mapUserKey, this.userKey)
        console.log('cumulate all response :>> ', response)
        this.setMapData(response)
        this.$buefy.snackbar.open({
          message: `Dias cumulados com sucesso.`,
          type: 'is-primary',
        })
      })

      this.savingData = false
    },

    async deleteDay(date) {
      console.log('deleteDay', date)
      if (!this.validations.can_edit_days) {
        console.warn('can_edit_days ', this.validations.can_edit_days)
        return this.$buefy.snackbar.open({
          message: `O dia ${date} não pode ser removido.`,
          type: 'is-warning',
        })
      }

      this.savingData = true
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.deleteManageUserMapDays(this.mapKey, this.mapUserKey, this.userKey, [
          Dates.formatDateToAPI(date),
        ])
        console.log('deleteDay response :>> ', response)
        this.setMapData(response)
        this.$buefy.snackbar.open({
          message: `O dia ${date} foi removido da lista.`,
          type: 'is-light',
        })
      })

      this.savingData = false
    },

    async changeStatus(status, comment = null) {
      console.log('changeStatus: ', { status, comment })
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.updateManageUserMapStatus(this.mapKey, this.mapUserKey, this.userKey, {
          status,
          comment,
        })
        console.log('updateUserMapStatus :>> ', response)
        this.userMap.status = response.user_map.status
        this.userMap.version = response.user_map.version
        this.userMap.canceled_at = response.user_map.canceled_at
        this.userMap.submitted_at = response.user_map.submitted_at

        this.validations = response.permissions
        this.isMapManager = response.roles?.is_map_manager
        this.isMapCreditdaysManager = response.roles?.is_map_creditdays_manager
        this.isMapApprover = response.roles?.is_map_approver
        this.isMapCreditdaysApprover = response.roles?.is_map_creditdays_approver
        this.isMapReporter = response.roles?.is_map_reporter
        this.isMapGlobalReporter = response.roles?.is_map_global_reporter
        this.userMap.blocked = response.user_map.blocked
        this.$refs.sidebarList.getUserMaps()
      })
    },

    cancelMapUser() {
      console.log('----> cancelMapUser')
      if (!this.validations.can_cancel) {
        console.warn('can_cancel ', this.validations.can_cancel)
        return
      }

      this.$buefy.dialog.confirm({
        type: 'is-dark',
        title: 'Cancelar pedido de alterações',
        message: 'Tem a certeza que pretende cancelar esta versão do seu mapa de férias?',
        onConfirm: async () => {
          const response = await this.changeStatus('canceled')
          if (!response) return
          this.$buefy.snackbar.open({
            message: `A versão do seu mapa de férias foi cancelada.`,
            type: 'is-light',
          })
        },
        confirmText: 'Cancelar versão',
        cancelText: 'Fechar',
      })
    },

    submitMapUser() {
      console.log('----> submitMapUser')
      if (!this.canSubmitVersion) {
        console.warn('can_submit ', this.canSubmitVersion)
        return
      }

      this.$buefy.dialog.confirm({
        type: 'is-primary',
        title: 'Submeter mapa de férias',
        message: 'Tem a certeza que pretende submeter esta versão do seu mapa de férias?',
        onConfirm: async () => {
          const response = await this.changeStatus('submitted')
          if (!response) return
          this.$buefy.snackbar.open({
            message: `A versão do seu mapa de férias foi submetida para aprovação.`,
            type: 'is-primary',
            position: 'is-top-right',
          })
        },
        confirmText: 'Submeter',
        cancelText: 'Cancelar',
      })
    },
  },
}
</script>

<i18n>
  {
    "pt": {
      "syncOperations": {
        "add": "Adicionado",
        "del": "Removido",
        "acc": "Cumulado"
      },
      "types": {
        "allday": "Dia inteiro",
        "morning": "Manhã",
        "afternoon": "Tarde",
        "halfday": "Dia parcial",
        "creditday": "Dia de crédito",
        "creditday-morning": "Dia de crédito Manhã",
        "creditday-afternoon": "Dia de crédito Tarde",
        "creditday-halfday": "Dia de crédito parcial",
        "holiday": "Feriado",
        "birthday": "Aniversário"
      }
    },
    "en": {
      "syncOperations": {
        "add": "Adicionado",
        "del": "Removido",
        "acc": "Cumulado"
      },
      "types": {
        "allday": "Dia inteiro",
        "morning": "Manhã",
        "afternoon": "Tarde",
        "halfday": "Dia parcial",
        "creditday": "Dia de crédito",
        "creditday-morning": "Dia de crédito manhã",
        "creditday-afternoon": "Dia de crédito tarde",
        "creditday-halfday": "Dia de crédito parcial",
        "holiday": "Feriado",
        "birthday": "Aniversário"
      }
    }
  }
</i18n>
